<template>
  <div class="info" style="margin-top: 0;">
    <van-popup v-model="timeShow"   position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="timeList" value-key="label"  @confirm="timeConfirm"  @cancel="timeShow = false"/>
    </van-popup>
    <van-cell-group>
      <van-cell title="核酸检测时间" is-link :value="dataForm.date"  @click="timeShow = true" required v-if="timeList.length > 0"/>
      <van-cell title="核酸检测结果" :border="false" required/>
      <van-uploader v-model="dataForm.fileList"  :multiple="false" @delete="uploaddel" :before-read="beforeUpload" :after-read="uploadRead"/>
    </van-cell-group>
    <van-row class="btns">
      <van-col :span="24" style="padding-top: 80px">
        <van-button type="info" size="large" round @click="submit">确认上报</van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {beforeFileUpload, formatterDateTimeWithOutSec} from "@/utils/utils";
export default {
  data () {
    return {
      dataForm: {
        userId: '',
        fileMessages:[],
        newFileMessages:[],
        deleteFiles: [],
        fileList: [],
        file: '',
        date: '请选择'
      },
      timeShow: false,
      timeList: []
    }
  },
  computed: {...mapState(['houseId'])},
  methods: {
    // 删除图片
    uploaddel (file, detail) {
      let i = detail.index
      this.dataForm.fileMessages.splice(i, 1)
    },
    // 文件上传前的回调
    beforeUpload (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
        overlay: true
      })
      return beforeFileUpload(file)
    },
    // 上传图片
    uploadRead (file) {
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file);
        formdata.append("path", `files/app/isolation/report/${this.$orgId}/temporary`);
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({data}) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            this.dataForm.fileMessages.push(data.fileMessage)
            this.dataForm.newFileMessages.push(data.fileMessage)
            return true
          } else {
            this.$toast.fail(data.msg);
          }
        })
      })
    },
    getDate() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/disease/isolation/res/report/selectDateList'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId,
          userId: this.dataForm.userId,
          houseId: this.houseId
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.timeList = data.list
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    timeConfirm(value) {
      if (value) {
        this.dataForm.date = value
      }
      this.timeShow = false
    },
    submit () {
      if (this.dataForm.newFileMessages.length < 1) {return this.$toast.fail('失败:请先上传结果');}
      let path = this.dataForm.newFileMessages.map(item => item.relativePath).join(',')
      this.$http({
        url: this.$http.adornUrl('/wxapp/disease/isolation/res/report/save'),
        method: 'post',
        data: this.$http.adornData({
          'reportTime': this.dataForm.date == '请选择' ? '' : this.dataForm.date,
          'userId': this.dataForm.userId,
          'community': this.$orgId,
          'path': path
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.$toast.success('新增成功')
          this.$router.back()
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
  },
  mounted() {
    this.dataForm.userId = this.$route.query.userId
    this.getDate()
  }
}
</script>

<style lang="scss" scoped>
.notice {
  .cont {
    padding: 0 28px 160px;
    .van-cell {
      padding: 10px 15px;
    }
  }
  .van-tag {
    color: #1A67FF;
    background-color: rgba(78, 124, 245, 0.12);
    padding: 8px 35px !important;
    float: right;
  }
  .divider {
    height: 1px;
    width: 100%;
    background-color: #e5e5e5;
    margin: 25px 0;
  }
  .remark {
    color: #333;
    font-weight: 600;
    font-size: 16px;
  }
}
</style>
